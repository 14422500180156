.App {
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  background-color: #eeeeee;
}

.my-thumbs {
  margin-top: 2em;
  width: 20em;
  height: 29.5em;
  pointer-events: none;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0,0,0,0);
  background-repeat: no-repeat;
  background-size: 100%;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

swiper-slide:nth-child(1) {
  background-image: url("static/J2.png");
}
swiper-slide:nth-child(2) {
  background-image: url("static/2.2.png");
}
swiper-slide:nth-child(3) {
  background-image: url("static/4.5.png");
}
swiper-slide:nth-child(4) {
  background-image: url("static/K4.png");
}
swiper-slide:nth-child(5) {
  background-image: url("static/A.5.png");
}
swiper-slide:nth-child(6) {
  background-image: url("static/2.7.png");
}
swiper-slide:nth-child(7) {
  background-image: url("static/Q7.png");
}
swiper-slide:nth-child(8) {
  background-image: url("static/6.4.png");
}

.nav {
  position: absolute;
  bottom: 0;
  margin: 0.5em;
  width: calc(100dvw - 1em);
  background-color: #bbbbbb;
  border-radius: 2em;
  * {
    height: 5em;
    background-color: rgba(0,0,0,0);
    background-image: unset !important;
    fill: white;
  }
}

.pill {
  width: 4em;
  height: 35%;
  background-color: white;
  border-radius: 3em;
}
